
























































































import { Component, Prop, Watch } from 'vue-property-decorator';
import EntitySearchQuery from '@/utils/types/entity-search/EntitySearchQuery';
import InputNumberComponent from '@/components/InputNumberComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import FormatHelper from '@/utils/helpers/FormatHelper';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: {
    FontAwesomeComponent,
    ButtonComponent,
    InputNumberComponent,
  },
  inheritAttrs: false,
})
export default class EntitySearchRangeWidget extends BreakpointWrapper {
  @Prop({
    required: false,
    default: null,
  })
  private storeName!: string;

  @Prop({ required: true })
  private filterLabel!: string;

  @Prop({ required: true })
  private code!: string;

  @Prop({ required: true })
  private clearFilterTriggered!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private state!: boolean;

  private isOpen = false;

  private rangeMin!: number;

  private rangeMax: number | null = null;

  private hasSelection = false;

  created(): void {
    this.initRange();
    if (
      this.$route.query
      && typeof this.$route.query.ranges === 'object'
      && 'price' in this.$route.query.ranges
    ) {
      const priceRange = this.$route.query.ranges as { price: (number | null)[] };
      this.rangeMin = priceRange.price[0] ? priceRange.price[0] : 0;
      this.rangeMax = priceRange.price[1] ?? null;
    }
    this.$root.$on(`toggle-filter-${this.code}`, this.toggleFilter);
  }

  mounted(): void {
    this.hasSelection = this.rangeMin > 0 || this.rangeMax !== null;
    this.$emit('filters-update', [this.priceLabelFilter()], this.code);
  }

  onApplyFilter(): void {
    if (this.rangeMin === 0 && !this.rangeMax) {
      this.onCancelFilter();
      this.hasSelection = false;
      this.$emit('filters-update', [], this.code);
    } else {
      this.hasSelection = true;
      const { query } = this.$route;
      if (!('ranges' in query && query.ranges)) {
        Object.assign(query, { ranges: {} });
      }
      const ranges:
        Record<string, [number, number] | [number]> = {};
      if (this.rangeMax) {
        ranges[this.code] = [this.rangeMin, this.rangeMax];
      } else {
        ranges[this.code] = [this.rangeMin];
      }
      Object.assign(query.ranges, { ...ranges });
      this.$emit('filters-update', [this.priceLabelFilter()], this.code);
      this.$emit('on-route-query-changed', this.$route.query);

      // eslint-disable-next-line no-restricted-globals
      history.pushState({ [this.code]: ((query as EntitySearchQuery).ranges ?? {})[this.code] },
        '',
        this.$router.resolve({
          path: this.$route.path,
          params: this.$route.params,
          query,
        }).href);
      this.toggleRangeClick();
    }
  }

  onCancelFilter(): void {
    const { query } = this.$route;
    delete ((query as EntitySearchQuery).ranges ?? {})[this.code];
    if ('ranges' in query && query.ranges && Object.keys(query.ranges).length === 0) {
      delete query.ranges;
    }
    this.$emit('filters-update', [], this.code);
    this.$emit('on-route-query-changed', this.$route.query);

    // eslint-disable-next-line no-restricted-globals
    history.pushState({ [this.code]: ((query as EntitySearchQuery).ranges ?? {})[this.code] },
      '',
      this.$router.resolve({
        path: this.$route.path,
        params: this.$route.params,
        query,
      }).href);
    this.toggleRangeClick();
    this.initRange();
  }

  // eslint-disable-next-line class-methods-use-this
  private priceTag(price: number): string {
    return `${FormatHelper.formatCurrency(price)}`;
  }

  private priceLabelFilter(): string {
    if (this.rangeMin || this.rangeMax) {
      if (this.rangeMin > 0 && this.rangeMax) {
        return `${this.priceTag(this.rangeMin)} - ${this.priceTag(this.rangeMax)}`;
      }
      if (this.rangeMin > 0) {
        return `${this.$t('filter-component.ranges-label.minimum')} ${this.priceTag(this.rangeMin)}`;
      }
      if (this.rangeMax) {
        return `${this.$t('filter-component.ranges-label.maximum')} ${this.priceTag(this.rangeMax)}`;
      }
    }
    return '';
  }

  @Watch('clearFilterTriggered', { deep: true })
  private initRange(): void {
    this.rangeMin = 0;
    this.rangeMax = null;
    this.hasSelection = false;
    this.$emit('reset-clear-filter-triggered');
  }

  @Watch('state')
  private stateChange(): void {
    this.isOpen = this.state;
  }

  private onClickOutside(): void {
    this.isOpen = false;
    this.$root.$emit(`toggle-filter-${this.code}`, this.isOpen);
  }

  private toggleRangeClick(): void {
    this.isOpen = !this.isOpen;
    this.$root.$emit(`toggle-filter-${this.code}`, this.isOpen);
  }

  private toggleFilter(isOpen: boolean): void {
    this.$emit('toggle-filter-state', this.code, isOpen);
  }
}
