






































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import EntityFilterToggle from '@/utils/types/entity-search/EntityFilterToggle';
import EntityFilterList from '@/utils/types/entity-search/EntityFilterList';
import EntitySearchToggleComponent from '@/components/entity-search/EntitySearchToggleWidget.vue';
import EntitySearchDateComponent from '@/components/entity-search/EntitySearchDateWidget.vue';
import EntitySearchListComponent from '@/components/entity-search/EntitySearchListWidget.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import SelectedListParams from '@/utils/types/SelectedListParams';
import PillWidget from '@/components/pill/PillWidget.vue';
import Variant from '@/utils/enums/Variant';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { EntitySearchFilterType } from '@/utils/enums/EntitySearchFilterType';
import useTestDataAttribute from '@/utils/TestDataAttribute';
import EntitySearchRangeWidget from '@/components/entity-search/EntitySearchRangeWidget.vue';
import EntityFilterRange from '@/utils/types/entity-search/EntityFilterRange';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import EntitySearchQuery from '@/utils/types/entity-search/EntitySearchQuery';

@Component({
  methods: { useTestDataAttribute },
  components: {
    EntitySearchRangeWidget,
    FontAwesomeComponent,
    PillWidget,
    ButtonComponent,
    EntitySearchListComponent,
    EntitySearchDateComponent,
    EntitySearchToggleComponent,
    InputSearchComponent,
  },
})
export default class EntitySearchFilterWidget extends VueBaseWidget {
  @Prop({
    required: false,
    default: null,
  })
  private storeName!: string;

  @Prop({ required: true })
  private search!: boolean;

  @Prop({ required: true })
  private placeholder!: string;

  @Prop({
    required: false,
    default: 'gray',
  })
  private readonly searchColor!: string;

  @Prop({
    required: false,
    default: false,
  })
  private togglePillStyle!: boolean;

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly listConfigs!: EntityFilterList[];

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly toggleConfigs!: EntityFilterToggle[];

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly rangeConfigs!: EntityFilterRange[];

  @Prop({
    required: false,
    default: null,
  })
  private entityType!: string;

  private variantEnum = Variant;

  private searchValue = '';

  private showClearButton = false;

  private clearFilterTriggered = false;

  private clearRangeTriggered = false;

  private openToggleFilters = false;

  private entitySearchFilterState: Record<string, boolean> | null = null;

  private allSelectedFilters: { [key: string]: string[] } = {};

  private selectedFilterList: { [key: string]: SelectedListParams[] } = {};

  private renderSelectedFilterList = 0;

  private renderSelectedToggles = 0;

  private emptySelectedFilterList = true;

  private EntitySearchFilterType = EntitySearchFilterType;

  private selectedToggleFilters: Array<string> = [];

  private readonly priceCode = 'price';

  created(): void {
    if ('toggles' in this.$route.query
      && this.$route.query.toggles) {
      Object.keys(this.$route.query.toggles)
        .forEach((toggle) => {
          this.selectedToggleFilters.push(toggle);
        });
    }
    this.entitySearchFilterState = {};
    this.listConfigs.forEach((listConfig) => {
      if (this.entitySearchFilterState) {
        this.$set(this.entitySearchFilterState, listConfig.code, false);
      }
    });
    this.rangeConfigs.forEach((rangeConfig) => {
      if (this.entitySearchFilterState) {
        this.$set(this.entitySearchFilterState, rangeConfig.key, false);
      }
    });
  }

  applyFilters(): void {
    this.showClearButton = this.toggleShowClearFilter();
    this.$emit('apply-entity-search');
  }

  applyToggle(code: string): void {
    if (this.selectedToggleFilters.includes(code)) {
      this.selectedToggleFilters.splice(this.selectedToggleFilters.indexOf(code), 1);
    } else {
      this.selectedToggleFilters.push(code);
    }
    this.$emit('apply-entity-search');
  }

  applySearch(payload?: { query: string }): void {
    const { query } = this.$route;
    if (payload && payload.query && payload.query.length > 0) {
      Object.assign(query, { search: payload?.query });
    } else {
      delete query.search;
    }
    const route = this.$router.resolve({
      path: this.$route.path,
      params: this.$route.params,
      query,
    }).href;
    window.history.pushState({ search: payload?.query }, '', route);
    if (this.$route.query && 'search' in this.$route.query && this.$route.query.search.length > 0) {
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code || '',
        '',
        StatLoggerActions.SEARCH,
        this.$route.query.search as string,
        -1,
        '',
        this.entityType,
        this.$i18n.locale,
      );
    }
    this.$emit('apply-entity-search');
  }

  mounted(): void {
    this.searchValue = this.$route.query.search as string;
    this.showClearButton = this.toggleShowClearFilter();
    this.$emit('apply-entity-search');
  }

  clearToggles(): void {
    delete this.$route.query.toggles;
    // eslint-disable-next-line no-restricted-globals
    history.pushState(history.state,
      '',
      this.$router.resolve({
        path: this.$route.path,
        params: this.$route.params,
        query: this.$route.query,
      }).href);
    this.selectedToggleFilters = [];
    this.renderSelectedToggles = +new Date();
    this.$emit('apply-entity-search');
  }

  private toggleShowClearFilter(): boolean {
    return (
      Object.keys(this.$route.query.filters ?? []).length > 0
      || Object.keys(this.$route.query.dates ?? []).length > 0
    );
  }

  private manageFilterState(code: string, isOpen: boolean): void {
    if (this.entitySearchFilterState) {
      Object.keys(this.entitySearchFilterState)
        .forEach((key) => {
          if (this.entitySearchFilterState) {
            this.$set(this.entitySearchFilterState, key, false);
          }
        });
      this.$set(this.entitySearchFilterState, code, isOpen);
    }
  }

  private onFiltersUpdate(selectedIds: string[], code: string): void {
    if (this.allSelectedFilters) {
      this.allSelectedFilters[code] = selectedIds;
    }
    this.updateSelectedFilterList(code);
  }

  private updateSelectedFilterList(code: string): void {
    this.selectedFilterList[code] = [];
    if (!this.allSelectedFilters[code].includes('all')) {
      const listConfig = this.listConfigs.find((config) => config.code === code);
      if (listConfig) {
        const dataQuery = listConfig.dataQuery as SelectedListParams[];
        this.selectedFilterList[code] = dataQuery.filter((item) => this.allSelectedFilters[code]
          .includes(item.id));
        this.selectedFilterList[code].forEach((filter) => {
          filter.isDateFilter = true;
          filter.selected = true;
        });
      } else if (code === this.priceCode && this.allSelectedFilters[code][0]) {
        this.selectedFilterList[code] = [{
          id: this.priceCode,
          title: this.allSelectedFilters[code][0],
          selected: true,
          isDateFilter: false,
        }];
      }

      const dateConfig = this.listConfigs
        .find((config) => config.code === code && config.listType === EntitySearchFilterType.DATE);
      if (dateConfig) {
        const dataQuery = dateConfig.dataQuery as SelectedListParams[];
        this.selectedFilterList[code] = dataQuery.filter((item) => this.allSelectedFilters[code]
          .includes(item.id));
        this.selectedFilterList[code].forEach((filter) => {
          filter.isDateFilter = true;
          filter.selected = true;
        });
      }

      const sessionDateConfig = this.listConfigs
        .find((config) => config.code === code && config.listType === EntitySearchFilterType.SESSION_DATE);
      if (sessionDateConfig) {
        const dataQuery = sessionDateConfig.dataQuery as SelectedListParams[];
        this.selectedFilterList[code] = dataQuery.filter((item) => this.allSelectedFilters[code]
          .includes(item.id));
        this.selectedFilterList[code].forEach((filter) => {
          filter.selected = true;
        });
      }
    }
    this.renderSelectedFilterList += 1;
    this.updateSelectedFilterSubList();
  }

  @Watch('selectedFilterList')
  private updateSelectedFilterSubList(): void {
    let isEmpty = true;
    Object.keys(this.selectedFilterList)
      .forEach((key) => {
        if (this.selectedFilterList[key].length > 0) {
          isEmpty = false;
        }
      });
    this.emptySelectedFilterList = isEmpty;
  }

  private clearAllFilters(): void {
    delete this.$route.query.filters;
    delete this.$route.query.ranges;
    delete this.$route.query.dates;
    // eslint-disable-next-line no-restricted-globals
    history.pushState(history.state,
      '',
      this.$router.resolve({
        path: this.$route.path,
        params: this.$route.params,
        query: this.$route.query,
      }).href);
    this.clearFilterTriggered = true;
    this.clearRangeTriggered = true;
    this.selectedFilterList = {};
    this.$emit('apply-entity-search');
  }

  private onClearFilter(selectedFilter: SelectedListParams): void {
    if (selectedFilter.id === this.priceCode) {
      this.onClearRange();
    } else {
      Object.keys(this.$route.query.filters)
        .forEach((k) => {
          if (this.allSelectedFilters[k].includes(selectedFilter.id)) {
            const index = this.allSelectedFilters[k].indexOf(selectedFilter.id);

            const selectedIndex = this.selectedFilterList[k].indexOf(selectedFilter);
            if (selectedIndex > -1) {
              const splicedArray = this.selectedFilterList[k].filter((value, i) => i !== selectedIndex);
              this.$set(this.selectedFilterList, k, splicedArray);
              this.renderSelectedFilterList += 1;
            }

            if (index > -1) {
              const query = this.$route.query as unknown as { filters: Record<string, string[]> };
              if (query.filters[k].length === 1) {
                delete query.filters[k];
                this.$eventsBus.emit('clear-all-pills-entity-search', { code: k });
                this.allSelectedFilters[k] = [];
              } else {
                query.filters[k].splice(index, 1);
              }
            }
          }
        });
    }
    this.$emit('apply-entity-search');
  }

  private onClearRange(): void {
    const { query } = this.$route;
    delete ((query as EntitySearchQuery).ranges ?? {})[this.code];
    delete query.ranges;

    // eslint-disable-next-line no-restricted-globals
    history.pushState({ price: ((query as EntitySearchQuery).ranges ?? {}).price },
      '',
      this.$router.resolve({
        path: this.$route.path,
        params: this.$route.params,
        query,
      }).href);
    this.$set(this.selectedFilterList, this.priceCode, []);
    this.renderSelectedFilterList += 1;
    this.clearRangeTriggered = true;
    this.updateSelectedFilterSubList();
  }
}
